<template>
  <div class="main-account-manager">
    <b-card title="Quản lý tài khoản">
      <b-row>
        <b-col lg="4">
          <div class="search-account-input">
            <b-form-input
              v-model="modelDepartmentSearch.name"
              type="text"
              placeholder="Nhập tên tài khoản để tìm kiếm"
              @keyup.enter="getListAllAccount"
            />
          </div>
        </b-col>
        <b-col lg="4">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="getListAllAccount"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-school-block">
            <b-row>
              <b-col
                lg="12"
                style="margin: auto"
              >
                <div class="acction-add-account mb-2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="getFormAddNewEmployee"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Thêm mới</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <span style="color: green">Có tổng số {{ totalCountList }} bản ghi</span>
            <b-table
              responsive
              :items="listAccount"
              :per-page="perrPage"
              :current-page="currentPage"
              :fields="fields"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                  v-b-tooltip.hover.top="'Cập nhật'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="updateAccount(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Xóa'"
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deleteDepartment(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <template>
              <b-pagination
                v-model="currentPage"
                class="float-right"
                :per-page="perrPage"
                hide-goto-end-buttons
                :total-rows="rows"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BPagination,
} from 'bootstrap-vue'

import { required, max } from '@validations'

import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listAccount: [],
      currentPage: 1,
      perrPage: 10,
      indexListDepartment: null,
      required,
      max,
      idDepartment: null,
      isDepartment: false,
      modelDepartment: {
        email: null,
        name: null,
        userManagementRequestDTOList: [
        ],
        username: null,
      },
      isValidateUserName: false,
      totalRecordsList: 0,
      totalCountList: null,
      modelDepartmentSearch: {
        email: null,
        name: null,
        userManagementRequestDTOList: [
        ],
        username: null,
      },
      fields: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'userName', label: 'Tên đăng nhập',
        },
        {
          key: 'name', label: 'Tên tài khoản',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-action-account', tdClass: 'custom-td-action-account',
        },
      ],
      listPerPage: [
        3, 5, 10,
      ],
    }
  },
  computed: {
    rows() {
      if (this.listAccount) {
        return this.listAccount.length
      }
    },
  },
  created() {
    this.getListAllAccount()
  },
  methods: {
    getListAllAccount() {
      this.listAccount = []
      const userCheckRole = JSON.parse(localStorage.getItem('userCheckRole'))
      this.$crm.post('user/find-by-condition', this.modelDepartmentSearch).then(res => {
        res.data.map(n => {
          if (n.userName.toLowerCase() !== userCheckRole.username.toLowerCase()) {
            this.listAccount.push(n)
          }
        })
        this.totalCountList = this.listAccount.length
      })
    },
    getFormAddNewEmployee() {
      this.$router.push('/manager-account/add-manager-account')
    },
    updateAccount(item) {
      this.$router.push({
        name: 'update-manager-account',
        query: {
          id: item.id,
          name: item.userName,
        },
      })
    },
    deleteDepartment(item) {
      this.$swal({
        title: 'Xóa tài khoản',
        text: `Bạn có muốn xóa tài khoản ${item.userName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`user/delete/${item.id}`).then(res => {
            this.getListAllAccount()
            this.$swal({
              icon: 'success',
              title: 'Xóa tài khoản!',
              text: 'Xóa tài khoản thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/account';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
